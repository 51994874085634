export default class DeviceTableItem {
    infoId = 0;
    style = "";
    work = "";
    type = 0;
    tags = "";
    send = 0;
    receive = "";
    id = "";
    orgCode = "";
    deviceId = "";
    machineLayer = "";
    moduleName = "";
    moduleType = "";
    level = "";
    psmStatus = 0;
    psmStatusName = "";
    status = 0;
    statusName = "";
    productionDatetime = "";
    createTime = "";
    projectIdName = "";
    typeName = "";
}
