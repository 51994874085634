import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import DeviceTableItem from "@/entity/module/DeviceTableItem";
import API from "@/api/module/device";
import {ElMessageBox} from "element-plus";
// import { ElMessageBox } from "element-plus";

@Options({
    name: "app-module-message",
})
export default class Device extends BaseTableMixins<DeviceTableItem> implements Partial<BaseTableInterface<DeviceTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new DeviceTableItem();
        this.editModalItem = new DeviceTableItem();
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //删除
    private delMonitorData(item: DeviceTableItem) {
        const infoId = item.infoId;
        ElMessageBox.confirm("是否要删除这数据", "注意", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(() => {
                this.isLoading = true;
                API.delMonitorData({ infoId })
                    .then((response) => {
                        //console.log(response.data);
                        this.toast.success("删除成功");
                        this.getList(0);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            })
            .catch(() => {});
    }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除数据",
            message: "姓名: " + this.editModalItem.projectIdName + "<br>请确认删除？",
        };
    }

    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.infoId)) return "设备状态ID不能为空！";
        return null;
    }

}
